<!-- 专项讲解-- 列表 -->
<template>
  <list-template
      :loading="loading"
      hasAdd
      @onAdd="$router.push('./add')"
      @onReset="onReset"
      :search-config="searchConfig"
      @onSearch="onSearch"
      :isDownload="true"
      @onDownload="getData(1)"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleEdit"
  ></list-template>
</template>

<script>
import {mapState} from 'vuex'
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search_type: [],  //校区年级班级科目筛选方式，1校区，2年级，3班级，4科目
      search: {},
      searchConfig: [
        {prop: 'teacher_name', placeholder: '搜索教师姓名', default: ''},
        {
          prop: 'school_id', tag: "select", placeholder: '筛选校区', options: [],
          label: 'name', value: 'id',
          change: (e, search) => this.schoolChange(e, search)
        },
        {
          prop: 'grade_id', tag: "select", placeholder: '筛选年级', options: [],
          label: 'name', value: 'id',
          change: (e, search) => this.gradeChange(e, search)
        },
        {
          prop: 'teaching_id', tag: "select", placeholder: '筛选班级', options: [],
          label: 'name', value: 'id',
          change: (e, search) => this.classChange(e, search),
        },
        {
          prop: 'subject_id', tag: "select", placeholder: '筛选讲解科目', options: [],
          label: 'name', value: 'subject_id',
          change: (e, search) => this.subjectChange(e, search)
        },
      ],
      tableConfig: [
        {prop: 'teacher_name', label: '讲解教师'},
        {prop: 'subject_name', label: '讲解科目'},
        {prop: 'title', label: '讲解标题'},
        {prop: 'accept', label: '讲解接收人'},
        {prop: 'solve_time', label: '讲解时间'},
        {prop: 'teaching', label: '班级'},
        {prop: 'grade_info', label: '年级'},
        {prop: 'school_name', label: '校区'},
        {prop: 'handle', label: '操作', width: '180rem', handle: true, buttons: [{ type:"view", text:"查看" }, { type:"studentFeedback", text:"学生反馈" }]}
      ],

      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
    }
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getConfig()
  },
  activated() {
    this.getData()
    this.getConfig()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    getConfig() {
      this.$_axios2.get('api/common/school', {
        params: {
          level: 2,
          ispeiyou: 1
        }
      }).then(res => {
        let data = res.data.data;
        this.searchConfig[1].options = data.school
        this.searchConfig[4].options = data.subject_data
      })
    },

    getData(type = 0) {
      let params = {...this.search, page: this.page};
      if (type === 1) {
        params.export = 1
      } else {
        this.loading = true;
        this.tableData = []
      }
      this.$_axios2('api/question-group/student/question-group-list', {params}).then(res => {
        if (type === 1) {
          if (typeof res.data === 'string') tools.download('专项讲解', res.data)
        } else {
          let data = res.data.data
          this.tableData = data.list
          this.total = data.page.total
        }
      }).finally(() => {
        this.loading = false;
      })
    },

    schoolChange(e, search) {
      if (!this.search_type.includes(1)) this.search_type.push(1)
      search.grade_id = ''
      search.teaching_id = ''
      if (this.search_type.indexOf(1) === 0) {
        search.subject_id = ''
        this.search_type = [1]  //重置年级和科目的筛选顺序
      }
      this.searchConfig[3].options = []
      this.search = search
      let params = {school_id: search.school_id, level: 2,ispeiyou: 1}
      if (this.search_type.indexOf(1) === 1) params.subject_id = search.subject_id
      this.$_axios2.get('api/common/school', {params}).then(res => {
        let data = res.data.data;
        this.searchConfig[2].options = data.grade
        if (this.search_type.indexOf(1) === 0) this.searchConfig[4].options = data.subject_data
      })

    },

    gradeChange(e, search) {
      if (!this.search_type.includes(2)) this.search_type.push(2)
      search.teaching_id = ''
      if (this.search_type.indexOf(2) === 1) search.subject_id = ''
      this.search = search
      let params = {
        school_id: search.school_id,
        grade_id: search.grade_id,
        level: 2,
        ispeiyou: 1
      }
      if (this.search_type.indexOf(2) === 2) params.subject_id = search.subject_id
      this.$_axios2.get('api/common/school', {params}).then(res => {
        let data = res.data.data;
        this.searchConfig[3].options = data.teaching_data
        if (this.search_type.indexOf(2) === 1) this.searchConfig[4].options = data.subject_data
      })
    },

    classChange(e, search) {
      if (this.search_type.indexOf(4) === -1) {
        this.searchConfig[3].options.forEach(item => {
          if (item.id === e) search.subject_id = item.subject_id
          this.search = search
        })
      }
    },

    subjectChange(e, search) {
      if (!this.search_type.includes(4)) this.search_type.push(4)
      search.teaching_id = ''
      let params = {subject_id: search.subject_id, level: 2,ispeiyou: 1}
      if (this.search_type.indexOf(4) === 0) {
        search.school_id = ''
        search.grade_id = ''
        this.searchConfig[2].options = []
        this.searchConfig[3].options = []
      } else if (this.search_type.indexOf(4) === 1) {
        search.grade_id = ''
        this.searchConfig[3].options = []
        params.subject_id = search.subject_id
        params.school_id = search.school_id
      } else {
        params.subject_id = search.subject_id
        params.school_id = search.school_id
        params.grade_id = search.grade_id
      }
      this.search = search
      this.$_axios2.get('api/common/school', {params}).then(res => {
        let data = res.data.data;
        if (this.search_type.indexOf(4) === 0) {
          this.searchConfig[1].options = data.school
        } else if (this.search_type.indexOf(4) === 1) {
          this.searchConfig[2].options = data.grade
        } else {
          this.searchConfig[3].options = data.teaching_data
        }
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val;
      this.getData()
    },

    handleEdit(row, handle) {
      const { type } = handle
      if (type === 'view') {
        this.$router.push('./details?id=' + row.id)
      }
      if (type === 'studentFeedback') {
        this.$router.push('./feedback?id=' + row.id)
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit('setPage', val)
      //拉取数据
      this.getData()
    },

    onReset() {
      this.search_type = []
      this.searchConfig[2].options = []
      this.searchConfig[3].options = []
      this.getConfig()
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__header {
  padding: 20rem 20rem 0;
}

.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
